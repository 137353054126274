import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { enableMapSet } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';

import App from 'App';
import history from 'common/history';
import reportWebVitals from 'reportWebVitals';

import 'global.css';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';
import '../node_modules/leaflet/dist/leaflet.css';
import '../node_modules/react-tabs/style/react-tabs.css';
import '../node_modules/react-datepicker/dist/react-datepicker.css';

enableMapSet();

const onRedirectCallback = (appState?: AppState) => {
  history.replace(appState?.returnTo || window.location.pathname);
};

const AUTH0_DOMAIN = 'auth.drvnsolutions.app';
const AUTH0_AUDIENCE = 'https://fleet-portal.eu.auth0.com/api/v2/';

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || 'WkrnamnVmys7hytfYeLJSErCZn1mKi8p'}
      audience={AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
